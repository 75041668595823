
import './App.css';
import logo from './logo.png'
import logoKidDiary from './s_kiddiaryicon.png'
// import ReactGA from 'react-ga';
// import TagManager from 'react-gtm-module'
import GA4React from "ga-4-react";

import { useEffect, useState, useRef } from 'react';


let ga4react = null;


export async function init(G) {
  if(!GA4React.isInitialized()){
    try {
      ga4react = new GA4React("G-NPEST2Q6EN")
      await ga4react.initialize();
      ga4react.event("open_website")
      ga4react.pageview("/")
    } catch (error) {
      console.error(error);
    }
  };
};

function App() {

  const refScroll = useRef(null)

  init()


  const [ gender, setGender ] = useState('M')

  const [ birthDate, setBirthDate ] = useState(parseInt(getCurrentDay()))
  const [ birthMonth, setBirthMonth ] = useState(parseInt(getCurrentMonth()))
  const [ birthYear, setBirthYear ] = useState(parseInt(getCurrentYear()))

  const [ birthDateValue, setBirthDateValue ] = useState(parseInt(getCurrentDay()))
  const [ birthMonthValue, setBirthMonthValue ] = useState(parseInt(getCurrentMonth()))
  const [ birthYearValue, setBirthYearValue ] = useState(parseInt(getCurrentYear()))


  const [ measureDate, setMeasureDate ] = useState(parseInt(getCurrentDay()))
  const [ measureMonth, setMeasureMonth ] = useState(parseInt(getCurrentMonth()))
  const [ measureYear, setMeasureYear ] = useState(parseInt(getCurrentYear()))

  const [ measureDateValue, setMeasureDateValue ] = useState(parseInt(getCurrentDay()))
  const [ measureMonthValue, setMeasureMonthValue ] = useState(parseInt(getCurrentMonth()))
  const [ measureYearValue, setMeasureYearValue ] = useState(parseInt(getCurrentYear()))

  const [ inputType, setInputType ] = useState(1)

  const [ kindMonth, setKindMonth ] = useState(0)
  const [ kindMonthText, setKindMonthText ] = useState(0)

  const [ kindWeight, setKindWeight ] = useState('')
  const [ kindHeight, setKindHeight ] = useState('')

  const [ kindDirectMonth , setKindDirectMonth ] = useState(0)
  const [ kindDirectYear , setKindDirectYear ] = useState(0)

  const [ errorMessage , setErrorMessage ] = useState("")

  const [ graphImage , setGraphImage ] = useState("")

  const [ result, setResult ] = useState({
    "kind_month" : "-",
    "kind_gender" : "-",
    "kind_birthday" : "-",
    "kind_height" : "-",
    "kind_weight" : "-",
    "measure_date" : "-",
    "ideal" : "-",
    "mean" : "-",
    "weight_level" : 0,
    "weight_color" : '',
    "height_level" : 0,
    "height_color" : '',
    "ha" : '-',
    "ha_persentage" : '-',
  })


  let itemsDate = [
    { "id":1,"description":"1" },
    { "id":2,"description":"2" },
    { "id":3,"description":"3" },
    { "id":4,"description":"4" },
    { "id":5,"description":"5" },
    { "id":6,"description":"6" },
    { "id":7,"description":"7" },
    { "id":8,"description":"8" },
    { "id":9,"description":"9" },
    { "id":10,"description":"10" },
    { "id":11,"description":"11" },
    { "id":12,"description":"12" },
    { "id":13,"description":"13" },
    { "id":14,"description":"14" },
    { "id":15,"description":"15" },
    { "id":16,"description":"16" },
    { "id":17,"description":"17" },
    { "id":18,"description":"18" },
    { "id":19,"description":"19" },
    { "id":20,"description":"20" },
    { "id":21,"description":"21" },
    { "id":22,"description":"22" },
    { "id":23,"description":"23" },
    { "id":24,"description":"24" },
    { "id":25,"description":"25" },
    { "id":26,"description":"26" },
    { "id":27,"description":"27" },
    { "id":28,"description":"28" },
    { "id":29,"description":"29" },
    { "id":30,"description":"30" },
    { "id":31,"description":"31" },
  ]

  let itemsMonth = [
    { "id":1,"description":"มกราคม" },
    { "id":2,"description":"กุมภาพันธ์" },
    { "id":3,"description":"มีนาคม" },
    { "id":4,"description":"เมษายน" },
    { "id":5,"description":"พฤษภาคม" },
    { "id":6,"description":"มิถุนายน" },
    { "id":7,"description":"กรกฎาคม" },
    { "id":8,"description":"สิงหาคม" },
    { "id":9,"description":"กันยายน" },
    { "id":10,"description":"ตุลาคม" },
    { "id":11,"description":"พฤศจิกายน" },
    { "id":12,"description":"ธันวาคม" },
  ]

  let itemsYear = []
  initYear(100)

  const spinnerBirthDate = (event) =>{
      setBirthDateValue(parseInt(event.target.value))
      setBirthDate(parseInt(event.target.value));
      setKindMonth(getKidMonth());
  }

  const spinnerBirthMonth = (event) =>{
    setBirthMonthValue(parseInt(event.target.value))
    setBirthMonth(parseInt(event.target.value));
    setKindMonth(getKidMonth());
  }
  const spinnerBirthYear = (event) =>{
    setBirthYearValue(parseInt(event.target.value))
    setBirthYear(parseInt(event.target.value));
    setKindMonth(getKidMonth());
  }



  const spinnerMeasureDate = (event) =>{
    setMeasureDateValue(parseInt(event.target.value))
    setMeasureDate(parseInt(event.target.value));
    setKindMonth(getKidMonth());
}

const spinnerMeasureMonth = (event) =>{
  setMeasureMonthValue(parseInt(event.target.value))
  setMeasureMonth(parseInt(event.target.value));
  setKindMonth(getKidMonth());
}
const spinnerMeasureYear = (event) =>{
  setMeasureYearValue(parseInt(event.target.value))
  setMeasureYear(parseInt(event.target.value));
  setKindMonth(getKidMonth());
}


  function initYear( total ){
    let nowDate = getCurrentDate("-")
    let nowDateArray = nowDate.split("-")


    for(let i = 0; i <= total; i++){
      let yearEng = parseInt(nowDateArray[0])-i
      let yearTh = yearEng+543
      itemsYear.push({ "id": yearEng, "description": yearTh})
    }
  }

  function getCurrentDate(separator=''){
    let date = getCurrentDay()
    let month = getCurrentMonth()
    let year = getCurrentYear()
    
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
  }


  function getCurrentYear(){
    let newDate = new Date()
    return newDate.getFullYear()
  }
  function getCurrentMonth(){
    let newDate = new Date()
    return newDate.getMonth() + 1;
  }
  function getCurrentDay(){
    let newDate = new Date()
    return newDate.getDate();
  }


  function getHeightLevel( levelIndex ){
    if(result.height_level === levelIndex){
      return result.height_color
    }
    return ''
  }

  function getWeightHeightLevel( levelIndex ){
    if(result.weight_level === levelIndex){
      return result.weight_color
    }
    return ''
  }
  
  function getKidMonthDirect(){
    return kindDirectMonth+(kindDirectYear*12)
  }

  function validate(){

    const regWeight = /[0-9]+$/;
    const regHeight = /[0-9]+$/;

    if((inputType === 2 && ( kindDirectYear <= 0 && kindDirectMonth <= 0 )) ){
      setErrorMessage("กรุณาระบุ อายุ ปีและเดือน เป็นตัวเลข จำนวนเต็ม")
      return false
    }
    if((inputType === 2 &&  regWeight.test(kindDirectYear) === false) || kindDirectYear < 0){
      setErrorMessage("กรุณาระบุ อายุ ปี เป็นตัวเลข จำนวนเต็ม")
      return false
    }
    if((inputType === 2 &&  regWeight.test(kindDirectMonth) === false)  || kindDirectMonth < 0 ){
      setErrorMessage("กรุณาระบุ อายุ เดือน เป็นตัวเลข จำนวนเต็ม")
      return false
    }


  
    if(kindHeight === 0 || kindHeight < 0 || regHeight.test(kindHeight) === false){
      setErrorMessage("กรุณาระบุ ส่วนสูง เป็นตัวเลข หรือ ทศนิยม")
      return false
    }

   
    if(kindWeight === 0 || kindWeight < 0 || regWeight.test(kindWeight) === false){
      setErrorMessage("กรุณาระบุ น้ำหนัก เป็นตัวเลข หรือ ทศนิยม")
      return false
    }
    setErrorMessage("")
    return true
  }


  function reset(){
    setGender('M')

    setBirthDate(parseInt(getCurrentDay()))
    setBirthMonth(parseInt(getCurrentMonth()))
    setBirthYear(parseInt(getCurrentYear()))

    setBirthDateValue(parseInt(getCurrentDay()))
    setBirthMonthValue(parseInt(getCurrentMonth()))
    setBirthYearValue(parseInt(getCurrentYear()))


  setMeasureDate(parseInt(getCurrentDay()))
  setMeasureMonth(parseInt(getCurrentMonth()))
  setMeasureYear(parseInt(getCurrentYear()))

  setMeasureDateValue(parseInt(getCurrentDay()))
  setMeasureMonthValue(parseInt(getCurrentMonth()))
  setMeasureYearValue(parseInt(getCurrentYear()))

  setInputType(1)

  setKindMonth(0)
  setKindMonthText(0)

  setKindWeight('')
  setKindHeight('')

  setKindDirectMonth(0)
  setKindDirectYear(0)

  setErrorMessage("")

  setGraphImage("")
  setResult({
    "kind_month" : "-",
    "kind_gender" : "-",
    "kind_birthday" : "-",
    "kind_height" : "-",
    "kind_weight" : "-",
    "measure_date" : "-",
    "ideal" : "-",
    "mean" : "-",
    "weight_level" : 0,
    "weight_color" : '',
    "height_level" : 0,
    "height_color" : '',
    "ha" : '-',
    "ha_persentage" : '-',
  })
  }

  function callApi( ){

  
  

    let m = getKidMonth()
    if(inputType === 2){
      m = getKidMonthDirect()
    }
    let mTxt = getKidYearMonth(m)
    setKindMonth( m )

    const requestOptions = {
      method: 'GET'
    };
    setResult({    
      "kind_month" : "-",
      "kind_gender" : "-",
      "kind_birthday" : "-",
      "kind_height" : "-",
      "kind_weight" : "-",
      "measure_date" : "-",
      "ideal" : "-",
      "mean" : "-",
      "weight_level" : 0,
      "weight_color" : '',
      "height_level" : 0,
      "height_color" : '',
      "ha" : '-',
      "ha_persentage" : '-',
    })

    if(validate()){


      let endPoint  = `https://sync.kiddiary.in.th/ext2?cmd=all&height_age=${m}&gender=${gender}&height=${kindHeight}&weight=${kindWeight}&weight_age=${m}`
      //console.log(endPoint)
      fetch(endPoint, requestOptions)
      .then(response => response.json())
      .then(data => resultApi(data, mTxt, gender, m, kindWeight, kindHeight), (error) => {
        if (error) {
          // handle error here
          // setShowMessageContent('กรุณาลองใหม่อีกครั้ง')
          // showModalMessage()
        }
      });


    }
  }

  function callGraphApi(g, m, w, h){
    setGraphImage("")
    const requestOptions = {
      method: 'GET',
    };
    let endPoint  = `https://chart.kiddiary.in.th/nutri/getGraph.php?gender=${g}&age=${m}&weight=${w}&height=${h}`
    fetch(endPoint, requestOptions)
    .then(response => response.json())
    .then(data => resultGraphApi(data), (error) => {
      if (error) {
      }
    });
  }

  function resultGraphApi(data){
    //console.log(data)

    refScroll.current.scrollIntoView({  behavior: 'smooth' }) 
    setGraphImage(data.path)


    
    //track
    if(ga4react !== null){
      try {
        ga4react.event("click_button_calculate")
        ga4react.event("calulate_"+gender)
      
      } catch (error) {
        console.log(error)
      }
    }
  }

  function resultApi( data , mTxt, g, m, w, h){
    //console.log(data)
    //console.log(kindMonthText)
    
    let w4hLevel = 0
    let w4hColor = 0
    if(data.w4h.message !== null){
      w4hLevel = data.w4h.message.level
      w4hColor = data.w4h.message.color
    }

    
    let heightLevel = 0
    let heightColor = 0
    if(data.height.message !== null){
      heightLevel = data.height.message.level
      heightColor = data.height.message.color
    }

    let ibwValue = 0
    let ibwMean = 0
    if(data.ibw.message !== null){
      ibwValue = `${data.ibw.message.median} กิโลกรัม`
      ibwMean = `${(data.ibw.message.percent)} % ของ IBW`
    }

    let dob = getKidBirthDay()
    let measureFullDate = getKidMeasure()
    if(inputType === 2){
      dob = "-"
      measureFullDate = "-"
    }

    let haValue = 0
    let haPersentage = 0
    if(data.hPercent.message !== null){
      haValue = data.hPercent.message.median
      haPersentage = data.hPercent.message.percent
      haValue = `${haValue} เซนติเมตร`
      haPersentage = `${haPersentage} % median HA`

    }
    else{
      haValue = "-"
      haPersentage = "-"
    }

    setResult({
      "kind_month" : mTxt,
      "kind_gender" : (gender === "M")? 'ชาย' : 'หญิง',
      "kind_birthday" : dob,
      "kind_height" : `${kindHeight} เซนติเมตร`,
      "kind_weight" : `${kindWeight} กิโลกรัม`,
      "measure_date" : measureFullDate,
      "ideal" : ibwValue,
      "mean" : ibwMean,
      "weight_level" : w4hLevel,
      "weight_color" : w4hColor,
      "height_level" : heightLevel,
      "height_color" : heightColor,
      "ha" : `${haValue}`,
      "ha_persentage" : `${haPersentage}`,
    
    })
    //console.log(result)
    callGraphApi(g, m, w, h)
  }

  function onChangeInputType(inp){
    setInputType(inp)
  }

  function getKidMeasure(){
    return measureDateValue+" "+getMonthTH(parseInt(measureMonthValue))+" "+(parseInt(measureYearValue)+543)
  }
  function getKidBirthDay(){
    return birthDateValue+" "+getMonthTH(parseInt(birthMonthValue))+" "+(parseInt(birthYearValue)+543)
  }
  function getMonthTH(m){
    for(let i = 0; i <itemsMonth.length; i++){
      if(itemsMonth[i].id === m){
        return itemsMonth[i].description
      }
    }
    return m
  }

  function getKidYearMonth(month){
    let y  = parseInt(parseInt(month)/12)
    let m = (parseInt(month)%12)
    //console.log("getKidYearMonth: "+kindMonth)
    let r = ""
    if(y > 0){
      r +=  `${y} ปี `
    }
    if(m > 0){
      r +=  `${m} เดือน`
    }
    if(r === ""){
      return "0 เดือน"
    }
    //console.log(r)
    return r
  }
  function getKidMonth(){
    let total = 0


    let totalMonthMeasure = (parseInt(measureYearValue)*12)+parseInt(measureMonthValue)
    let totalMonthBirthday = (parseInt(birthYearValue)*12)+parseInt(birthMonthValue)

    // console.log("measureYear:"+measureYearValue)
    // console.log("measureMonth:"+measureMonthValue)

    // console.log("birthYear:"+birthYearValue)
    // console.log("birthMonth:"+birthMonthValue)

    // console.log("totalMonthMeasure:"+totalMonthMeasure)
    // console.log("totalMonthBirthday:"+totalMonthBirthday)

    // console.log("measureDateValue:"+measureDateValue)
    // console.log("birthDateValue:"+birthDateValue)

    total = totalMonthMeasure - totalMonthBirthday

    if(  birthDateValue > measureDateValue){
      --total
    }

    if(total < 0){
      total = 0
    }

    //console.log("getKidMonth:"+total)
    return total
  }

  return (
    <div className="App">
      <div className="w-full flex flex-wrap">

        <div className="flex flex-wrap w-full  bg-red-600 p-4">
          <div className="flex  my-auto mx-auto xl:mx-0 xl:my-0">
            <img 
            className="w-24 h-24 xl:w-12 xl:h-12 "
            src={logo} 
            alt="logo"/>
          </div>
          <div className="flex flex-wrap text-center xl:text-left pl-0 xl:pl-5">
            <span className="text-start w-full text-white text-xl font-bold font-sarabun">Nutritional Status Calculator for Thai Children and Adolescents (NutStatCal)</span>
            <span className="text-start w-full text-white text-xs font-bold font-sarabun"> Version 2.0 อัปเดตล่าสุด วันที่ 20/11/2021</span>
          </div>
        </div>

        <div className="flex  flex-wrap w-full p-4 mt-5 border-b">
          <div className="flex  flex-wrap w-full xl:w-6/12 mx-auto justify-center">
            <div className="flex flex-wrap  w-full">
              <span className="mx-auto text-lg font-sarabun font-bold" ></span>
            </div>


            <div className="flex flex-wrap w-full mt-0 xl:mt-4">

              <div className="flex flex-wrap w-full xl:w-2/6">
                <input type="radio" checked={(inputType === 2)} name="in_type" value="2" className="my-auto mr-3"  onChange={ () => onChangeInputType(2) } />
                <span className="text-md font-sarabun font-bold my-auto" >อายุ (ปี, เดือน)</span>
              </div>
              <div className="flex flex-wrap w-full xl:w-4/6">
                {
                  (inputType === 1 )? null :
                  <div className="flex flex-wrap w-full   mt-2 xl:mt-0">
                    <input className="border rounded-md p-1 w-16 content-end" type="number" onChange={(event) => {   setKindDirectYear(parseInt(((event.target.value === "")? 0 : event.target.value)))}} pattern="[0-9]*"/>
                    <span className="text-md font-sarabun my-auto ml-3 mr-3 xl:mr-6 " >ปี</span>
                    <input className="border rounded-md p-1 w-16" type="number" onChange={(event) => setKindDirectMonth(parseInt(((event.target.value === "")? 0 : event.target.value)))} pattern="[0-9]*"/>
                    <span className="text-md font-sarabun my-auto  ml-3 mr-3 xl:mr-6" >เดือน</span>
                  </div>
                }
              </div>
              
            </div>

            <div className="flex flex-wrap w-full mt-8 xl:mt-4">

              <div className="flex flex-wrap w-full xl:w-2/6">
                <input type="radio" checked={(inputType === 1)} name="in_type" value="1"  className="my-auto mr-3" onChange={ () => onChangeInputType(1) }/>
                <span className="text-md font-sarabun font-bold my-auto" >วันเกิด </span>
              </div>
              <div className="flex flex-wrap w-full xl:w-4/6">
                {
                  (inputType === 2)? null :
                  <div className="flex flex-wrap w-full  mt-2 xl:mt-0">
                <select 
                value={birthDate}
                onChange={spinnerBirthDate}
                className="w-18 text-md border rounded-md py-1 mr-2 pl-2 font-sarabun">
                    {itemsDate.map(function(item, index){
                      return ( 
                        <option key={item.id} key={item.id} value={item.id}>{item.description}</option>
                      )})
                    }
                  </select>
                  <select 

                  value={birthMonth}
                  onChange={spinnerBirthMonth}
                  className="w-32 xl:w-36 text-md border rounded-md py-1 mr-2 pl-2 font-sarabun">
                    {itemsMonth.map(function(item, index){
                      return ( 
                        <option key={item.id} value={item.id}>{item.description}</option>
                      )})
                    }
                  </select>
                  
                  <select 
                  value={birthYear}
                  onChange={spinnerBirthYear}
                  className="w-20 xl:w-24 text-md border rounded-md py-1 mr-2 pl-2 font-sarabun">
                  {itemsYear.map(function(item, index){
                      return ( 
                        <option key={item.id} value={item.id}>{item.description}</option>
                      )})
                    }
                  </select>
                  </div>
                }
              </div>
              
          
            </div>

            {
              (inputType === 2)? null :
            <div className="flex flex-wrap w-full">
                <div className="flex flex-wrap w-full mt-12">

                <div className="flex flex-wrap w-full xl:w-2/6">
                  <span className="text-md font-sarabun font-bold my-auto" >วันที่ชั่งวัด</span>
                </div>
                <div className="flex flex-wrap w-full xl:w-4/6  mt-2 xl:mt-0">

                  <select 
                    value={measureDate}
                    onChange={spinnerMeasureDate}
                    className="w-18 text-md border rounded-md py-1 mr-2 pl-2 font-sarabun">
                        {
                          itemsDate.map(function(item, index){
                          return ( 
                            <option key={item.id} value={item.id}>{item.description}</option>
                          )})
                        }
                    </select>
                    <select 
                    value={measureMonth}
                    onChange={spinnerMeasureMonth}          
                    className="w-32 xl:w-36 text-md border rounded-md py-1 mr-2 pl-2 font-sarabun">
                      {itemsMonth.map(function(item, index){
                          return ( 
                            <option key={item.id} value={item.id}>{item.description}</option>
                          )})
                        }
                    </select>
                    <select 
                    value={measureYear}
                    onChange={spinnerMeasureYear}
                    className="w-20 xl:w-24 text-md border rounded-md py-1 mr-2 pl-2 font-sarabun">
                    {itemsYear.map(function(item, index){
                          return ( 
                            <option key={item.id} value={item.id}>{item.description}</option>
                          )})
                        }
                    </select>
                </div>
              </div>

            </div>

            }





            <div className="flex flex-wrap w-full mt-8 xl:mt-4">

              <div className="flex flex-wrap  w-2/6 xl:w-2/6">
                <span className="text-md font-sarabun font-bold" >เพศ</span>
              </div>
              <div className="flex flex-wrap  w-4/6 xl:w-4/6 pr-0 xl:pr-12">
                  <div className={"flex flex-wrap w-1/2 border rounded-tl-md  rounded-bl-md "+ (( gender === "M" )? ' bg-green-900 hover:bg-green-200 ' : ' bg-gray-200 ')}>
                    <button onClick={ () => setGender('M') } className="flex flex-wrap w-full">
                      <span className="m-auto font-sarabun">ชาย</span>
                    </button>
                  </div>
                  <div className={"flex flex-wrap w-1/2 border-r border-t border-b p-2 hover:bg-gray-200 rounded-tr-md  rounded-br-md  "+ (( gender === "F" )? ' bg-pink-300 hover:bg-pink-200 ' : ' bg-gray-200 ')}>
                    <button onClick={ () => setGender('F') } className="flex flex-wrap w-full">
                      <span className="m-auto font-sarabun">หญิง</span>
                    </button>
                  </div>
              </div>

            </div>


            <div className="flex flex-wrap w-full mt-4">

              <div className="flex flex-wrap  w-2/6 xl:w-2/6">
                <span className="text-md font-sarabun font-bold text-left " >ส่วนสูง (เซนติเมตร)</span>
              </div>
              <div className="flex flex-wrap  w-4/6 xl:w-4/6">
                <input 
                value={kindHeight}
                onChange={(event) => setKindHeight((event.target.value === "0")? 0 : parseFloat(event.target.value )) }
                className="border rounded-md p-1 w-full" type="number" />
              </div>
              
            </div>


            <div className="flex flex-wrap w-full mt-4">

              <div className="flex flex-wrap  w-2/6 xl:w-2/6">
                <span className="text-md font-sarabun font-bold text-left " >น้ำหนัก (กิโลกรัม)</span>
              </div>
              <div className="flex flex-wrap  w-4/6 xl:w-4/6">
                <input
                value={kindWeight}
                onChange={(event) => setKindWeight((event.target.value === "0")? 0 : parseFloat(event.target.value )) }
                className="border rounded-md p-1 w-full" type="number" />
              </div>
              
            </div>

            <div className="flex flex-wrap w-full mt-5 justify-center">
              <span className="text-red-500">{errorMessage}</span>
            </div>

            

            <div className="flex flex-wrap w-full mt-5 justify-center mb-12">
              <button onClick={callApi} className="flex py-2 px-6 md:px-12 border bg-green-900 rounded-xl font-sarabun  hover:bg-green-200">ประเมิน</button>
              <button onClick={ ()=> reset() } className="flex ml-5 md:ml-12 py-2 px-6 md:px-12 border bg-red-600 rounded-xl font-sarabun  hover:bg-red-200">Reset</button> 
            </div>

          </div>
        </div>


        <div className="flex  flex-wrap w-full p-4 mt-5 justify-center">
          <div className="flex  flex-wrap w-full xl:w-6/12">
            <span className="text-xs xl:text-sm font-sarabun text-justify">* NutStatCal โปรแกรมประเมินภาวะโภชนาการของเด็ก เพื่อวินิจฉัยภาวะเริ่มอ้วน (overweight) อ้วน (obesity) ผอม (wasting) ผอมแห้ง (severe wasted/severe acute malnutrition) เตี้ย (stunting) เตี้ยแคระแกร็น (severe stunted) แสดง % of ideal body weight และ % of median height-for-age โดยอ้างอิง มาตรฐานการเจริญเติบโตขององค์การอนามัยโลก ปี ค.ศ. 2006 (สำหรับเด็กอายุ 0-5 ปี) และ เกณฑ์อ้างอิงการเจริญเติบโตของเด็กอายุ 5-19 ปี สำนักโภชนาการ กรมอนามัย พ.ศ. 2563 (สำหรับเด็กอายุ 5-19 ปี)</span>
          </div>
        </div>
      
      
        <div className="flex  flex-wrap w-full p-4 mt-5">
          <div className="flex  flex-wrap w-full xl:w-8/12 mx-auto justify-center">
          <div  ref={refScroll} className="flex flex-wrap  w-full">
            <span className="mx-auto text-lg font-sarabun font-bold" >ผลการประเมินภาวะโภชนาการ</span>
          </div>


            <div className="flex flex-wrap  w-full mt-6">
              <div className="flex flex-wrap  w-3/6 xl:w-3/6">
                <span className="text-md font-sarabun font-bold" >อายุ</span>
              </div>
              <div className="flex flex-wrap  w-3/6 xl:w-3/6">
                <span className="text-md font-sarabun" >{result.kind_month}</span>
              </div>
            </div>

            <div className="flex flex-wrap  w-full mt-6">
              <div className="flex flex-wrap  w-3/6 xl:w-3/6">
                <span className="text-md font-sarabun font-bold" >เพศ</span>
              </div>
              <div className="flex flex-wrap  w-3/6 xl:w-3/6">
                <span className="text-md font-sarabun" >{result.kind_gender}</span>
              </div>
            </div>

            <div className="flex flex-wrap  w-full mt-6">
              <div className="flex flex-wrap  w-3/6 xl:w-3/6">
                <span className="text-md font-sarabun font-bold" >วันเกิด</span>
              </div>
              <div className="flex flex-wrap  w-3/6 xl:w-3/6">
                <span className="text-md font-sarabun" >{result.kind_birthday}</span>
              </div>
            </div>


            <div className="flex flex-wrap  w-full mt-6">
              <div className="flex flex-wrap  w-3/6 xl:w-3/6">
                <span className="text-md font-sarabun font-bold" >ส่วนสูง</span>
              </div>
              <div className="flex flex-wrap  w-3/6 xl:w-3/6">
                <span className="text-md font-sarabun" >{result.kind_height}</span>
              </div>
            </div>

            <div className="flex flex-wrap  w-full mt-6">
              <div className="flex flex-wrap  w-3/6 xl:w-3/6">
                <span className="text-md font-sarabun font-bold" >น้ำหนัก</span>
              </div>
              <div className="flex flex-wrap  w-3/6 xl:w-3/6">
                <span className="text-md font-sarabun" >{result.kind_weight}</span>
              </div>
            </div>


            <div className="flex flex-wrap  w-full mt-6">
              <div className="flex flex-wrap  w-3/6 xl:w-3/6">
                <span className="text-md font-sarabun font-bold" >วันที่วัด</span>
              </div>
              <div className="flex flex-wrap  w-3/6 xl:w-3/6">
                <span className="text-md font-sarabun" >{result.measure_date}</span>
              </div>
            </div>

            <div className="flex flex-wrap  w-full mt-6">
              <div className="flex flex-wrap  w-3/6 xl:w-3/6 content-start justify-start align-start">
                <span className="text-md font-sarabun  font-bold text-left" >Ideal Body Weight (IBW)</span>
              </div>
              <div className="flex flex-wrap  w-3/6 xl:w-3/6">
                <span className="text-md font-sarabun" >{result.ideal}</span>
              </div>
            </div>

            <div className="flex flex-wrap  w-full mt-6">
              <div className="flex flex-wrap  w-3/6 xl:w-3/6">
                <span className="text-md font-sarabun font-bold text-left" >น้ำหนักเป็น </span>
              </div>
              <div className="flex flex-wrap  w-3/6 xl:w-3/6">
                <span className="text-md font-sarabun" >{result.mean}</span>
              </div>
            </div>


            <div className="flex flex-wrap  w-full mt-6">
              <div className="flex flex-wrap  w-3/6 xl:w-3/6">
                <span className="text-md font-sarabun font-bold text-left" >Median Height-for-age (HA) </span>
              </div>
              <div className="flex flex-wrap  w-3/6 xl:w-3/6">
                <span className="text-md font-sarabun" >{result.ha}</span>
              </div>
            </div>


            <div className="flex flex-wrap  w-full mt-6">
              <div className="flex flex-wrap  w-3/6 xl:w-3/6">
                <span className="text-md font-sarabun font-bold text-left" >ส่วนสูงเป็น</span>
              </div>
              <div className="flex flex-wrap  w-3/6 xl:w-3/6">
                <span className="text-md font-sarabun" >{result.ha_persentage}</span>
              </div>
            </div>

          </div>

        
        
        </div>

        <div className="flex  flex-wrap w-full xl:w-8/12 mx-auto justify-center content-center pt-12 pb-12">

      

          <div className="flex flex-wrap  w-full mt-0">
            <span className="mx-auto text-lg font-sarabun font-bold align" >ส่วนสูงตามเกณฑ์อายุ (Height-for-age)</span>
          </div>

          <div className="flex flex-wrap  w-full mt-4 ">
                        
            <div className="flex flex-wrap  w-1/6 px-1 ">
              <div className="h-4 bg-gray-100 w-full"   style={ { background: getHeightLevel(0) }}>

              </div>
              <span className="mx-auto text-xs xl:text-sm font-sarabun  mt-1">เตี้ยแคระแกร็น <br/>(Servere stunted)</span>
            </div>

            <div className="flex flex-wrap  w-1/6 px-1 ">
              <div className="h-4 bg-gray-100 w-full"   style={ { background: getHeightLevel(1) }}>

              </div>
              <span className="mx-auto text-xs xl:text-sm font-sarabun  mt-1">เตี้ย <br/>(Stunted)</span>
            </div>

            <div className="flex flex-wrap  w-1/6 px-1">
              <div className="h-4 bg-gray-100 w-full"  style={ { background: getHeightLevel(2) }}>

              </div>
              <span className="mx-auto text-xs xl:text-sm font-sarabun  mt-1">ค่อนข้างเตี้ย</span>
            </div>

            <div className="flex flex-wrap  w-1/6 px-1">
              <div className="h-4 bg-gray-100 w-full"   style={ { background: getHeightLevel(3) }}>

              </div>
              <span className="mx-auto text-xs xl:text-sm font-sarabun  mt-1">ตามเกณฑ์</span>
            </div>
            <div className="flex flex-wrap  w-1/6 px-1">
              <div className="h-4 bg-gray-100 w-full"   style={ { background: getHeightLevel(4) }}>

              </div>
              <span className="mx-auto text-xs xl:text-sm font-sarabun  mt-1">ค่อนข้างสูง</span>
            </div>
            <div className="flex flex-wrap  w-1/6 px-1">
              <div className="h-4 bg-gray-100 w-full"    style={ { background: getHeightLevel(5) }}>

              </div>
              <span className="mx-auto text-xs xl:text-sm font-sarabun  mt-1">สูง</span>
            </div>
            
            

          </div>


          <div className="flex flex-wrap  w-full mt-10">
              <span className="mx-auto text-lg font-sarabun font-bold" >น้ำหนักตามเกณฑ์ส่วนสูง (Weight-for-height)</span>
          </div>

          <div className="flex   w-full mt-4  ">
            
          <div className="flex flex-wrap w-2/12 xl:w-28 px-0.5">
              <div className="flex h-4 bg-gray-100 w-full"   style={ { background: getWeightHeightLevel(0) }}>

              </div>
              <div className="flex w-full">
                <p className="mx-auto  text-xs font-sarabun  mt-1 ">ผอมแห้ง <br/>(Severe wasted<br/>/severe acute<br/> malnutrition)</p>
          
              </div>
            </div>

            <div className="flex flex-wrap w-2/12 xl:w-28 px-0.5 ">
              <div className="h-4 bg-gray-100 w-full"   style={ { background: getWeightHeightLevel(1) }}>

              </div>
              <span className="mx-auto text-xs xl:text-xs font-sarabun  mt-1">ผอม <br/>(Wasted)</span>
            </div>

            <div className="flex flex-wrap  w-2/12 xl:w-28 px-0.5">
              <div className="h-4 bg-gray-100 w-full"   style={ { background: getWeightHeightLevel(2) }}>

              </div>
              <span className="mx-auto text-xs xl:text-xs font-sarabun  mt-1">ค่อนข้างผอม</span>
            </div>

            <div className="flex flex-wrap w-2/12 xl:w-28 px-0.5">
              <div className="h-4 bg-gray-100 w-full"   style={ { background: getWeightHeightLevel(3) }}>

              </div>
              <span className="mx-auto text-xs xl:text-xs font-sarabun  mt-1">สมส่วน</span>
            </div>
            <div className="flex flex-wrap  w-2/12 xl:w-28  px-0.5">
              <div className="h-4 bg-gray-100 w-full"   style={ { background: getWeightHeightLevel(4) }}>

              </div>
              <span className="mx-auto text-xs xl:text-xs font-sarabun  mt-1">ท้วม</span>
            </div>
            <div className="flex flex-wrap  w-2/12 xl:w-28 px-0.5">
              <div className="h-4 bg-gray-100 w-full"   style={ { background: getWeightHeightLevel(5) }}>

              </div>
              <span className="mx-auto text-xs xl:text-xs font-sarabun  mt-1">เริ่มอ้วน <br/>(Overweight)</span>
            </div>
            <div className="flex flex-wrap  w-2/12 xl:w-28 px-0.5">
              <div className="h-4 bg-gray-100 w-full"   style={ { background: getWeightHeightLevel(6) }}>

              </div>
              <span className="mx-auto text-xs xl:text-xs font-sarabun  mt-1">อ้วน <br/>(Obese)</span>
            </div>
            
            

          </div>

          {
            (graphImage !== "") ?
            <div className="flex flex-wrap  w-full mt-4  ">
              <a href={graphImage} target="_blank" >
                <img
                src={graphImage}
                alt=""/>
              </a>
            </div>
            :
            null
          }
        </div>

      
      
      </div>

      <div className="flex flex-wrap  w-full mt-4  pb-3">
          <span className="flex flex-wrap  w-full text-sm xl:text-md  font-sarabun text-center justify-center">พัฒนาโดย</span>
          <span className="flex flex-wrap  w-full text-xs  xl:text-sm font-sarabun justify-center ">ศ.พญ.ลัดดา เหมาะสุวรรณ</span>
          <span className="flex flex-wrap  w-full text-xs  xl:text-sm font-sarabun justify-center">ดร.สุปิยา เจริญศิริวัฒน์</span>
          <span className="flex flex-wrap  w-full text-xs   xl:text-sm font-sarabun justify-center">นายพงษ์ศักดิ์ ติยานันทิ</span>
      </div>

      <footer className=" p-4   bg-red-600 text-white font-sarabun justify-center content-center">

          <img 
          src={logoKidDiary}
          className="w-8 h-8 mx-auto"
          alt=""
          />
        
        <span className="text-sm">Powered by KidDiary</span>
      </footer>
    </div>
  );
}

export default App;
